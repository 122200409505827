export default {
    message: {
        welcomePage: {
            title: 'Focus on personal user experience decentralized network cloud disk ',
            description: 'The Arweave ecosystem provides a decentralized cloud storage solution that is easier to use and more user-friendly, \nSupport direct and interactive purchase of Arweave permanent storage space on the Ethereum public chain. ',
            button: 'Use Now'
        },
        homePage: {
            storage: {
                title: 'Storage',
                description: 'The purchase space size is based on AR Token conversion, showing that the space size will fluctuate slightly',
                total: 'TOTAL',
                used: 'USED',
            },
            uploadFile: {
                description1: 'Drop file here, or ',
                description2: 'browse.',
                description3: 'one file at a time.',
                tagDescription: 'Enter a keyword, ENTER next'
            },
            fileList: {
                title: 'Files And Assets',
                fileName: 'FILE NAME',
                size: 'SIZE',
                tags: 'TAGS',
                time: 'TIME',
                viewButton: 'VIEW',
                myFileTab: 'My Storage',
                myCollectTab: 'My Collect',
                collected: 'Collected',
                noData: 'NO DATA'
            },
        },
        deposit: {
            title: 'BUY SPACE',
            description1: 'Powered by the everpay protocol',
            consume: 'CONSUME',
            inputPlaceholder: 'Input deposit amount',
            buy: 'BUY',
            balance: 'Available',
            chooseToken: 'choose token',
            chooseNetwork: 'choose network'
        },
        fileDetail: {
            explorer: 'AR EXPLORER',
            timestamp: 'Timestamp',
            size: 'Size',
            height: 'Height',
            FileType: 'File Type',
            fee: 'Fee',
            signature: 'Signature',
            audioText: 'Your browser does not support the audio tag.',
            videoText: 'Your browser does not support the video tag'
        },
        searchPage: {
            searchResult: 'Result',
            myFileTab: 'My Storage',
            allFileTab: 'All',
            selectPlaceholder: 'Please choose',
            fileDetailTab: 'File Detail',
            selectList: [{
                label: 'All',
                value: 'all'
                // }, {
                //     label: '文件名',
                //     value: 'fileName'
            }, {
                label: 'Tag',
                value: 'tag'
            }]
        },
        walletDialog: {
            connectedWalletTitle: 'connect Wallet',
            connectedWalletContent: 'Connect your wallet to use the Weweave service'
        },
        tokenSelectDialog: {
            balance: 'Balance',
            chain: 'ChainType',
            pay: 'Pay',
            tokenConnect: 'Connected payment token',
            tokenConnectText: 'Select a token from the top-up tokens \n to pay for this operation',
        },
        button: {
            cancel: 'CANCEL',
            upload: 'UPLOAD',
            uploading: 'UPLOADING...',
            deposit: 'DEPOSIT',
            back: 'BACK',
            logOut: 'LOG OUT',
            download: 'DOWNLOAD',
            copyUrl: 'COPY URL',
            collectButton: 'Collect'
        },
        resultText: {
            depositError: 'deposit error',
            depositSuccess: 'deposit success',
            NotAllowedDeposit: 'This currency is not supported',
            collectSuccess: 'Collection success'
        }
    }
}