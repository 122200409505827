<template>
<div class="layout-out-content__main">
  <el-container>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</div>
</template>

<script>
export default {
  name: "layoutPage",
  methods: {
    clickToAbout() {
      window.open('https://www.arweave.org/#arweave-intro')
    }
  }
}
</script>

<style scoped>
.layout-out-content__main {
  height: 100vh;
}
.header-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(217, 217, 217, 0.3);
  padding: 10px 40px;
  font-size: 24px;
}
.header-style-about {
  font-size: 16px;
  cursor: pointer;
}
.header-style-about:hover {
  text-decoration: underline;
}
.logo-style {
  width: auto;
  height: 60px;
}
</style>