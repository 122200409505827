export default {
    message: {
        welcomePage: {
            title: '专注个人用户体验的去中心化网络云盘 ',
            description: '基于Arweave生态，提供更加易用和友好的去中心化的云存储解决方案，\n支持直接在以太坊公链上直接交互购买Arweave永久存储空间。 ',
            button: '立即使用'
        },
        homePage: {
            storage: {
                title: '存储空间',
                description: '购买空间大小，是基于AR Token换算，显示空间大小会有轻微波动',
                total: '总空间',
                used: '已使用',
            },
            uploadFile: {
                description1: '将文件拖到此处，或',
                description2: '点击上传，',
                description3: '每次上传一个文件',
                tagDescription: '输入文字，以ENTER结束'
            },
            fileList: {
                title: '文件列表',
                fileName: '文件名',
                size: '文件大小',
                tags: '标签',
                time: '上传时间',
                viewButton: '查看',
                myFileTab: '我的存储',
                myCollectTab: '我的收藏',
                collected: '已收藏',
                noData: '暂无数据'
            },
        },
        deposit: {
            title: '购买空间',
            description1: '由everpay协议提供支持',
            consume: '资产',
            inputPlaceholder: '请输入充值数量',
            buy: '购买',
            balance: '可充值',
            chooseToken: '选择token',
            chooseNetwork: '选择网络'
        },
        fileDetail: {
            explorer: '跳转到AR',
            timestamp: '时间戳',
            size: '文件大小',
            height: '高度',
            FileType: '文件类型',
            fee: '费用',
            signature: '签名',
            audioText: '您的浏览器不支持 audio 标签。',
            videoText: '您的浏览器不支持 video 标签。'
        },
        searchPage: {
            searchResult: '搜索结果',
            myFileTab: '我的数据',
            allFileTab: '全链文件',
            selectPlaceholder: '请选择',
            fileDetailTab: '文件详情',
            selectList: [{
                label: '全部',
                value: 'all'
            // }, {
            //     label: '文件名',
            //     value: 'fileName'
            }, {
                label: '标签',
                value: 'tag'
            }]
        },
        walletDialog: {
            connectedWalletTitle: '连接钱包',
            connectedWalletContent: '连接钱包，以使用Weweave服务'
        },
        tokenSelectDialog: {
            balance: '余额',
            chain: '链',
            pay: '支付',
            tokenConnect: '连接支付token',
            tokenConnectText: '在已充值的token中选择一个token \n 以支付此次操作费用',
        },
        button: {
            cancel: '取消',
            upload: '上传',
            uploading: '上传中...',
            deposit: '充值',
            back: '返回',
            logOut: '取消连接',
            download: '下载',
            copyUrl: '复制链接',
            collectButton: '收藏'
        },
        resultText: {
            depositError: '充值失败',
            depositSuccess: '充值中,充值需要6个区块的等待时间',
            NotAllowedDeposit: '暂不支持此币种充值',
            collectSuccess: '收藏成功'
        }
    }
}