import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
// 多语言
import VueI18n from 'vue-i18n'
import zh from './i18n/zh'
import en from './i18n/en'
import './assets/style/resetCss.css'
import './assets/style/medium-editor.css'
import './assets/style/default.css'
import * as echarts from "echarts"
Vue.use(ElementUI)

Vue.use(VueI18n)

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

// 默认语言
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    'cn': zh,
    'en': en
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
