<template>
  <div class="layout-main-created">
    <el-container>
      <el-header class="header-style" height="70px">
        <div class="header-style-about">
<!--          <span class="link-btn" @click="clickTo">{{ showLinkBtnText }}</span>-->
          <el-popover
              placement="bottom"
              :title="null"
              width="100"
              trigger="click">
<!--            <span @click="logOut" style="cursor: pointer" class="log-out-style">LOG OUT</span>-->
<!--            <el-avatar slot="reference" size="medium" :style="`background-image: linear-gradient(${accountColor.top}, #fff, ${accountColor.bottom})`"></el-avatar>-->
<!--            <el-avatar slot="reference" size="medium" style="background: #6447F2"></el-avatar>-->
          </el-popover>
          <img src="../../assets/svg/logo-text.svg" alt="" @click="clickToHomePage"/>
<!--          <span class="layout-header-name">(beta)</span>-->
        </div>
        <div class="header-right-content">
          <div style="width: 260px" class="layout-header-input">
            <el-input
              v-model="searchInputValue"
              prefix-icon="el-icon-search"
              placeholder="Basic usage"
              width="260"
              @change="searchValue"
            ></el-input>
          </div>
          <div class="layout-header-select">
            <!--          <el-select v-model="selectValue" @change="selectSymbolFu" placeholder="Please Select" :disabled="true">-->
            <!--            <el-option-->
            <!--                v-for="(item, index) in symbolList"-->
            <!--                :key="index"-->
            <!--                :label="item.label"-->
            <!--                :value="item"/>-->
            <!--          </el-select>-->
            <!-- 中英文切换-->
            <div class="switch-chinese-english" style="margin-right: 10px;">
              <el-dropdown @command="handleLanguage">
              <span class="el-dropdown-link" style="color: #6447F2;font-weight: bold">
                {{ languageText }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="China">简体中文</el-dropdown-item>
                  <el-dropdown-item command="English">English</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <!-- 页头账户显示-->
            <div class="layout-header-right-box">
              <el-dropdown trigger="click" @command="handleCommand">
              <span class="layout-header-balance">
                <img src="../../assets/img/MetaMask.png" alt="" width="25" height="25" style="margin-right: 5px">
                <!--              {{ selectedSymbol ? balance : 0 }}{{selectedSymbol && balance ? selectedSymbol.symbol : ''}}-->
              {{account ? `${account.slice(0,4)}....${account.slice(-4)}` : ''}}
            </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-switch-button" command="logout">{{ $t("message.button.logOut") }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </el-header>
      <el-main>
        <keep-alive :include="keepAliveIncludeValue">
          <router-view />
        </keep-alive>
      </el-main>
      <el-footer>
        <HomePageFooter />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {strToHexCharCode, strToUtf8Bytes} from '@/utils/util'
import HomePageFooter from "@/views/Layout/footer"

export default {
  name: "layoutMain",
  components: {
    HomePageFooter
  },
  data() {
    return {
      value: undefined,
      searchInputValue: undefined,
      selectValue: "Ethereum",
      options: [],
      languageText: this.$i18n.locale === 'en' ? 'English' : '简体中文'
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
      symbolList: 'account/symbolList',
      balance: 'account/balance',
      selectedSymbol: 'account/selectedSymbol',
      aliveInclude: 'keepAliveInclude/aliveInclude'
    }),
    accountColor() {
      if (!this.account) return '#ffffff'
      const str = strToHexCharCode(strToUtf8Bytes(this.account))
      return {
        top: `#${str.slice(0, 6)}`,
        bottom: `#${str.substring(str.length - 6)}`
      }
    },
    keepAliveIncludeValue() {
      return this.aliveInclude
    }
  },
  methods: {
    ...mapActions({
      getInfo: 'account/getAccountInfo',
      updateSelectSymbol: 'account/updateSelectSymbol',
    }),
    async selectSymbolFu(item) {
      this.updateSelectSymbol(item)
    },
    handleCommand(command) {
      if (command === 'logout') {
        this.logOut()
      }
    },
    logOut() {
      sessionStorage.setItem('ACCOUNT_STRING', '')
      this.$router.push({
        name: 'Main'
      })
    },
    handleLanguage(language) {
      this.languageText = language === 'English' ? 'English' : '简体中文'
      if (language === 'English') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'cn'
      }
    },
    searchValue() {
      this.$router.push({
        name: 'Search',
        params: {
          searchValue: this.searchInputValue
        },
        query: {
          searchTab: this.$route.query.searchTab ? this.$route.query.searchTab : 'myFile'
        }
      })
      this.searchInputValue = ''
    },
    clickToHomePage() {
      this.$router.push({
        name: 'HomePage'
      })
    }
  }
}
</script>

<style lang="scss">
.layout-main-created{
  .header-right-content {

  }
}
.header-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 10px 40px;
  font-size: 24px;
  height: 70px;
  border-bottom: 1px solid #f5f5f5;
}
.header-style-about {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-style-about .link-btn {
  display: inline-block;
  width: 100px;
  height: 30px;
  /*background-color: white;*/
  border-bottom: 2px solid #222326;
  text-align: center;
  line-height: 30px;
  margin-right: 40px;
  /*border-radius: 5px;*/
  font-weight: bold;
}
.header-style-about .link-btn:hover {
  box-shadow: 8px 0px 8px 0 rgba(34, 35, 38, .3);
}
.log-out-style {
  text-align: center;
  display: inline-block;
  width: 150px;
  height: 30px;
  line-height: 30px;
}
.log-out-style:hover {
  color: white;
  background-color: #222326;
}
.logo-style {
  width: auto;
  height: 60px;
}
.layout-header-name {
  /*margin-left: 20px;*/
  font-weight: bold;
  font-size: 18px;
}
.layout-main-created .el-card__body, .el-main {
  padding: 0;
}
.layout-main-created .layout-main-created .el-input__inner {
  width: 128px;
  /*height: 44px;*/
  border-radius: 10px;
  background: #FAF9F8;
  border: none;
}
.header-right-content {
  width: 530px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.layout-header-input {
  .el-input__inner {
    border-radius: 10px;
  }
}
.layout-header-select {
  display: flex;
}
.layout-header-right-box {
  margin-left: 10px;
  width: 139px;
  border-radius: 10px;
  background: #FAF9F8;
  border: 2px solid #EFECFD;
  /*display: flex;*/
  text-align: center;
  cursor: pointer;
}
.switch-chinese-english {
  text-align: center;
  display: flex;
  align-items: center;
}
.layout-header-balance {
  /*display: inline-block;*/
  width: 113px;
  height: 40px;
  line-height: 43px;
  font-size: 12px;
  color: #7E8597;
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin-left: 10px;*/
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*padding: 0 10px;*/
}
.el-footer {
  padding: 0;
}
.el-dropdown-menu__item {
  font-size: 12px;
  color: #031425;
}
.el-dropdown-menu__item:hover {
  background: #EFECFD !important;
  color: #031425 !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>