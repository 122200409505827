export const chainStatus = {
    WAITING: 'waiting',
    PENDING: 'pending',
    SUCCESS: 'success',
    FAIL: 'failed'
}

export const paymentStatus = {
    UNPAID: 'unpaid',
    PAID: 'paid',
    EXPIRED: 'expired'
}

export const reportUrl = 'https://arseed.web3infra.dev'
export const redstoneUrl = "https://api.redstone.finance"
export const web3infuraIoUrl = "https://arseed.web3infra.dev/"
//export const adotArUrl = "https://api.a.site"
export const adotArUrl = "https://api.a.xyz"

export const ChainType = {
    ethereum: 'Ethereum',
    moon: 'Moonbeam',
    bsc: 'BSC',
}

export const adotAppName = {
    weweave: 'Weweave',
    smartWeaveContract: 'SmartWeaveContract',
    mirror: 'MirrorXYZ',
    twitter: 'TwittAR'
}
