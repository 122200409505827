import Vue from 'vue'
import Router from 'vue-router'

import Layout from '../views/Layout/layout'
import LayoutMain from '../views/Layout/layoutMain'

import store from '@/store'

Vue.use(Router)

const router = new Router({
  // mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'welcome',
      component: Layout,
      children: [
        {
          path: '',
          name: 'Main',
          component: () => import('../views/welcomePage')
        }
      ]
    },
    {
      path: '/',
      name: 'Home',
      component: LayoutMain,
      children: [
        {
          path: 'homepage',
          name: 'HomePage',
          component: () => import('../views/homePage')
        },
        {
          path: 'detail/:id',
          name: 'FileDetail',
          component: () => import('../views/fileDetailPage')
        },
        {
          path: 'deposit',
          name: 'Deposit',
          component: () => import('../views/depositPage')
        },
        {
          path: 'search/:searchValue',
          name: 'Search',
          component: () => import('../views/searchPage')
        },
      ]
    }
  ]
})

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
}

router.beforeEach(async (to, from, next) => {
  if (to.name !== 'Main') {
    const info = store.getters["account/account"]
    if (!info) {
      return next({ name: 'Main' })
    }
  }
  next()
})

export default router
