<template>
  <div class="home-page-footer__main">
    <div class="home-page-footer">
<!--      <div class="home-page-footer-box">-->
<!--        <div class="footer-box-left">-->
<!--          <div class="footer-box-left-title">-->
<!--&lt;!&ndash;            <span class="footer-box-left-circle"></span>&ndash;&gt;-->
<!--            <img src="../../assets/svg/logo-text.svg" alt="" />-->
<!--            <span style="font-size: 18px">(beta)</span>-->
<!--          </div>-->
<!--          <div class="footer-box-left-content">-->
<!--&lt;!&ndash;            ARStoj base arweave storage protocol. Having the most innovative and flexible user experience uploading file on arweave.&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="footer-box-left-icon"></div>-->
<!--        </div>-->
<!--        <div class="footer-box-right">-->
<!--          <div class="footer-box-right-box">-->
<!--            <p class="footer-box-right-title">Products</p>-->
<!--            <p>Sweep buy</p>-->
<!--            <p>Limit Buy</p>-->
<!--            <p>Collections</p>-->
<!--            <p>Portfolios</p>-->
<!--          </div>-->
<!--          <div class="footer-box-right-box">-->
<!--            <p class="footer-box-right-title">Help</p>-->
<!--            <p>Guides & tutorials</p>-->
<!--            <p>Media Kit</p>-->
<!--            <p>Help center</p>-->
<!--            <p>Report a bug</p>-->
<!--          </div>-->
<!--          <div class="footer-box-right-box">-->
<!--            <p class="footer-box-right-title">Developers</p>-->
<!--            <p>Github</p>-->
<!--            <p>Development</p>-->
<!--            <p>API</p>-->
<!--          </div>-->
<!--          <div class="footer-box-right-box">-->
<!--            <p class="footer-box-right-title">Governance</p>-->
<!--            <p>Forum & Proposals</p>-->
<!--            <p>Vote</p>-->
<!--          </div>-->
<!--          <div class="footer-box-right-box">-->
<!--            <p class="footer-box-right-title">Partners</p>-->
<!--            <p>everVision</p>-->
<!--            <p>everPay</p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="home-page-footer-bottom-text">
        <span>Copyright © 2023 WeWeave. All rights reserved.</span>
                <div class="footer-box-left">
<!--                  <div class="footer-box-left-title">-->
<!--        &lt;!&ndash;            <span class="footer-box-left-circle"></span>&ndash;&gt;-->
<!--                    <img src="../../assets/svg/logo-text.svg" alt="" />-->
<!--                    <span style="font-size: 18px">(beta)</span>-->
<!--                  </div>-->
                  <div class="footer-box-left-icon"></div>
                </div>
<!--        <span>Terms & privacy</span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.home-page-footer__main {
  width: 100%;
  /*height: 331px;*/
  background: #FCFCFC;
}
.home-page-footer {
  width: 1307px;
  margin: 0 auto;
}
.home-page-footer-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-box-title {

}
.footer-box-left {
  /*margin-top: 70px;*/
  width: 379px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.footer-box-left-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #6447F2;
  margin-right: 20px;
}
.footer-box-left-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #09244B;
  font-weight: bold;
}
.footer-box-left-content {
  margin: 20px 0;
  font-size: 14px;
  color: #A9A8B0;
  /*height: 51px;*/
}
.footer-box-left-icon {
  height: 20px;
  width: 100px;
  background: url("../../assets/img/Social-Icon.png");
}
.footer-box-right {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
}
.footer-box-right-box {
  width: 138px;
  color: #A9A8B0;
  margin-right: 50px;
}
.footer-box-right-box:last-child {
  margin-right: 0;
}
.footer-box-right-box p {
  margin-bottom: 6px;
  font-size: 14px;
}
.footer-box-right-title {
  color: #09244B;
  font-weight: bold;
  margin-bottom: 10px;
}
.home-page-footer-bottom-text {
  width: 1307px;
  height: 80px;
  line-height: 60px;
  /*border-top: 1px solid #F1F2F2;*/
  color: #A9A8B0;
  font-size: 14px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-footer {
  padding: 0;
}
</style>